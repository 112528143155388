<template>
    <div>
        <card-header title="Trip Reports" icon="fa-clipboard-list-check"/>

        <card-body>
            <card-list>
                <a :href="`${apiUrl}api/trips/${trip.id}/reports/participants?auth=${currentUser.token}`" target="_blank">
                    <navigation-list-item title="Participants" icon="fa-users" class="t-card-list-item"/>
                </a>
                <a :href="`${apiUrl}api/trips/${trip.id}/reports/shirt_summary?auth=${currentUser.token}`" target="_blank">
                    <navigation-list-item title="Shirt/Jacket Sizes Summary" icon="fa-tshirt" class="t-card-list-item"/>
                </a>
                <a :href="`${apiUrl}api/trips/${trip.id}/reports/shirts?auth=${currentUser.token}`" target="_blank">
                    <navigation-list-item title="Participant Shirt/Jacket Sizes" icon="fa-tshirt" class="t-card-list-item"/>
                </a>
                <a :href="`${apiUrl}api/trips/${trip.id}/reports/flights?auth=${currentUser.token}`" target="_blank">
                    <navigation-list-item title="Participant Flights" icon="fa-plane" class="t-card-list-item"/>
                </a>
                <a :href="`${apiUrl}api/trips/${trip.id}/reports/transfers?auth=${currentUser.token}`" target="_blank">
                    <navigation-list-item title="Trip Bus/Vehicle Transfers" icon="fa-taxi-bus" class="t-card-list-item"/>
                </a>
                <a :href="`${apiUrl}api/trips/${trip.id}/reports/cars?auth=${currentUser.token}`" target="_blank">
                    <navigation-list-item title="Private Transfers" icon="fa-taxi" class="t-card-list-item"/>
                </a>
            </card-list>
        </card-body>
    </div>
</template>

<script>
    import CardHeader from "@/TIER/components/CardHeader";
    import CardList from "@/TIER/components/CardList";
    import SubheaderListItem from "@/TIER/components/SubheaderListItem";
    import SeparatorListItem from "@/TIER/components/SeparatorListItem";
    import NavigationListItem from "@/TIER/components/NavigationListItem";

    import {client as http, apiUrl} from '@/http_client';
    import CardBody from "@/TIER/components/CardBody";
    import {mapGetters} from "vuex";

    export default {
        name: "Users",
        props: ['card', 'child', 'props'],
        components: {CardBody, NavigationListItem, SeparatorListItem, SubheaderListItem, CardList, CardHeader},
        data: function() {
            return {
                trip: {},
                apiUrl
            }
        },
        computed: {
            ...mapGetters(['currentUser'])
        },
        methods: {
            loadTrip: function() {
                this.$emit('loading', true);
                http.get('/api/trips/' + this.props.tripId).then(response => {
                    this.trip = response.data;
                    this.$emit('loading', false);
                });
            },
            reload: function() {
                this.loadTrip(true);
            }
        },
        watch: {
            'props.tripId': function() {
                this.loadTrip();
            }
        },
        mounted() {
            this.loadTrip();
        }
    };
</script>

<style lang="scss" scoped>

</style>
